import './App.scss';
import React from 'react';
import { ArrowOfTimeBook } from './ArrowOfTimeBook';

function App() {
  return (
    <>
      <div className="book-block overflow-hidden">
        <div>
          <div className="container" style={{ position: "relative" }}>
            <ArrowOfTimeBook />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
